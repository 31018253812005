import React from 'react'
import { motion } from 'framer-motion'

const Industries: React.FC = () => {
  const industries = [
    {
      icon: "🏦",
      title: "Finanzas y Banca",
      description: "Soluciones digitales para instituciones financieras",
      expertise: [
        "Sistemas de pago digital",
        "Seguridad financiera",
        "Banca móvil",
        "Análisis de riesgos"
      ]
    },
    {
      icon: "🏭",
      title: "Manufactura",
      description: "Optimización de procesos industriales",
      expertise: [
        "Automatización industrial",
        "IoT industrial",
        "Gestión de inventario",
        "Control de calidad"
      ]
    },
    {
      icon: "🏪",
      title: "Retail",
      description: "Transformación del comercio minorista",
      expertise: [
        "E-commerce",
        "Gestión de inventario",
        "Experiencia del cliente",
        "Análisis de datos"
      ]
    },
    {
      icon: "🏥",
      title: "Salud",
      description: "Innovación en servicios médicos",
      expertise: [
        "Telemedicina",
        "Expedientes digitales",
        "Gestión hospitalaria",
        "Análisis clínico"
      ]
    },
    {
      icon: "🚛",
      title: "Logística",
      description: "Optimización de cadena de suministro",
      expertise: [
        "Tracking en tiempo real",
        "Optimización de rutas",
        "Gestión de flotas",
        "Almacenes inteligentes"
      ]
    },
    {
      icon: "🎓",
      title: "Educación",
      description: "Transformación educativa digital",
      expertise: [
        "Plataformas e-learning",
        "Gestión académica",
        "Contenido interactivo",
        "Analítica educativa"
      ]
    },
    {
      icon: "⚡",
      title: "Energía",
      description: "Innovación en el sector energético",
      expertise: [
        "Smart grids",
        "Energías renovables",
        "Eficiencia energética",
        "Monitoreo remoto"
      ]
    },
    {
      icon: "🏗️",
      title: "Construcción",
      description: "Digitalización de proyectos constructivos",
      expertise: [
        "BIM",
        "Gestión de proyectos",
        "IoT en construcción",
        "Seguridad digital"
      ]
    },
    {
      icon: "🏨",
      title: "Hotelería y Turismo",
      description: "Innovación en experiencias turísticas",
      expertise: [
        "Reservas digitales",
        "Experiencia del huésped",
        "Marketing digital",
        "Gestión hotelera"
      ]
    },
    {
      icon: "🌾",
      title: "Agroindustria",
      description: "Agricultura inteligente y sostenible",
      expertise: [
        "Agricultura de precisión",
        "IoT agrícola",
        "Trazabilidad",
        "Gestión de cultivos"
      ]
    },
    {
      icon: "🏢",
      title: "Servicios Profesionales",
      description: "Transformación de servicios B2B",
      expertise: [
        "Automatización de procesos",
        "CRM avanzado",
        "Gestión documental",
        "Colaboración digital"
      ]
    },
    {
      icon: "🏭",
      title: "Industria Química",
      description: "Innovación en procesos químicos",
      expertise: [
        "Control de procesos",
        "Seguridad industrial",
        "Gestión ambiental",
        "Laboratorio digital"
      ]
    }
  ]

  return (
    <section id="industrias" className="py-32">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Industrias</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Experiencia especializada en diversos sectores industriales con soluciones adaptadas a cada necesidad
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {industries.map((industry, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <IndustryCard {...industry} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}

interface IndustryCardProps {
  icon: string
  title: string
  description: string
  expertise: string[]
}

const IndustryCard: React.FC<IndustryCardProps> = ({ 
  icon, 
  title, 
  description, 
  expertise 
}) => (
  <div className="p-8 border border-white/10 rounded-xl bg-black/20 hover:border-neon-green/30 transition-all duration-300 h-full">
    <span className="text-4xl mb-4 block">{icon}</span>
    <h4 className="text-xl font-bold text-white mb-2">{title}</h4>
    <p className="text-gray-400 mb-6">{description}</p>
    <ul className="space-y-2">
      {expertise.map((item, index) => (
        <li key={index} className="flex items-center text-gray-300">
          <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
          {item}
        </li>
      ))}
    </ul>
  </div>
)

export default Industries 