import React from 'react'
import { motion } from 'framer-motion'

const Hero: React.FC = () => {
  return (
    <section className="min-h-[90vh] flex items-center">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <h1 className="text-6xl lg:text-7xl font-bold tracking-tight">
              <span className="text-white">Consultoría</span>
              <br />
              <span className="text-neon-green">Estratégica</span>
            </h1>
            <p className="text-xl text-gray-400 max-w-xl leading-relaxed">
              Especialista en transformación digital y gestión de proyectos tecnológicos. 
              Más de 15 años asesorando empresas en su evolución tecnológica, 
              desde la estrategia hasta la implementación.
            </p>
            <div className="flex flex-wrap gap-4">
              <motion.div 
                className="space-y-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <div className="flex flex-wrap gap-4">
                  <a 
                    href="#contacto" 
                    className="px-8 py-4 bg-neon-green text-black font-bold rounded hover:bg-neon-green/90 transition-colors"
                  >
                    Agenda una Consulta
                  </a>
                  <a 
                    href="#servicios" 
                    className="px-8 py-4 border border-white/20 text-white font-semibold rounded hover:bg-white/5 transition-colors"
                  >
                    Conoce los Servicios
                  </a>
                </div>
                <p className="text-sm text-gray-500">
                  Soluciones personalizadas para cada industria y necesidad
                </p>
              </motion.div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2 }}
            className="relative hidden lg:block"
          >
            {/* Elemento decorativo principal */}
            <div className="relative">
              {/* Círculo principal con gradiente mejorado */}
              <div className="absolute -inset-4 bg-gradient-to-tr from-neon-green/30 via-neon-green/5 to-transparent blur-2xl"></div>
              <div className="relative aspect-square rounded-full border border-white/10 p-8 backdrop-blur-sm">
                <div className="w-full h-full rounded-full bg-gradient-to-br from-neon-green/20 via-white/5 to-transparent"></div>
              </div>
              
              {/* Elementos flotantes con iconos y efectos */}
              <FloatingElement 
                text="Innovación"
                emoji="🚀"
                position="top-5 right-10"
                animation={{
                  y: [0, -20, 0],
                  rotate: [0, 5, 0],
                  scale: [1, 1.05, 1]
                }}
                duration={5}
              />
              
              <FloatingElement 
                text="Estrategia"
                emoji="💡"
                position="bottom-10 left-5"
                animation={{
                  y: [0, 15, 0],
                  rotate: [0, -3, 0],
                  scale: [1, 1.03, 1]
                }}
                duration={4}
                delay={0.5}
              />

              <FloatingElement 
                text="Agilidad"
                emoji="⚡"
                position="top-1/2 -right-10"
                animation={{
                  y: [-10, 10, -10],
                  x: [5, -5, 5],
                  rotate: [2, -2, 2]
                }}
                duration={6}
                delay={1}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

interface FloatingElementProps {
  text: string
  emoji: string
  position: string
  animation: any
  duration: number
  delay?: number
}

const FloatingElement: React.FC<FloatingElementProps> = ({ 
  text, 
  emoji, 
  position, 
  animation, 
  duration, 
  delay = 0 
}) => (
  <motion.div
    animate={animation}
    transition={{ 
      duration,
      repeat: Infinity,
      ease: "easeInOut",
      delay
    }}
    className={`absolute ${position} p-4 backdrop-blur-md bg-white/5 border border-white/10 rounded-xl shadow-lg`}
  >
    <div className="flex items-center gap-2">
      <span className="text-2xl">{emoji}</span>
      <span className="text-sm text-white/70">{text}</span>
    </div>
  </motion.div>
)

export default Hero 