import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {
  Hero,
  Services,
  Industries,
  Methodology,
  Frameworks,
  Cases,
  Testimonials,
  Contact
} from '../components/home'

const Home: React.FC = () => {
  return (
    <div className="relative">
      {/* Fondo negro sólido */}
      <div className="fixed inset-0 bg-black"></div>
      
      {/* Contenido principal */}
      <div className="relative">
        <Header />
        
        <main className="relative min-h-screen">
          <Hero />
          <Services />
          <Industries />
          <Methodology />
          <Frameworks />
          <Cases />
          <Testimonials />
          <Contact />
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default Home 