import React, { useState } from 'react'
import { motion } from 'framer-motion'
import confetti from 'canvas-confetti'
import InputMask from 'react-input-mask'

interface PhoneFormat {
  name: string
  mask: string
  regex: RegExp
  prefix: string
}

const PHONE_FORMATS: { [key: string]: PhoneFormat } = {
  CA: {
    name: "Canadá",
    mask: "### ###-####",
    regex: /^\d{10}$/,
    prefix: "+1"
  },
  US: {
    name: "Estados Unidos",
    mask: "### ###-####",
    regex: /^\d{10}$/,
    prefix: "+1"
  },
  MX: {
    name: "México",
    mask: "## ####-####",
    regex: /^\d{8}$/,
    prefix: "+52"
  },
  SV: {
    name: "El Salvador",
    mask: "####-####",
    regex: /^\d{8}$/,
    prefix: "+503"
  },
  CR: {
    name: "Costa Rica",
    mask: "####-####",
    regex: /^\d{8}$/,
    prefix: "+506"
  },
  PA: {
    name: "Panamá",
    mask: "####-####",
    regex: /^\d{8}$/,
    prefix: "+507"
  },
  CO: {
    name: "Colombia",
    mask: "### ###-####",
    regex: /^\d{10}$/,
    prefix: "+57"
  },
  CL: {
    name: "Chile",
    mask: "# ####-####",
    regex: /^\d{9}$/,
    prefix: "+56"
  },
  AR: {
    name: "Argentina",
    mask: "## ####-####",
    regex: /^\d{10}$/,
    prefix: "+54"
  },
  BR: {
    name: "Brasil",
    mask: "## #####-####",
    regex: /^\d{11}$/,
    prefix: "+55"
  },
  ES: {
    name: "España",
    mask: "### ### ###",
    regex: /^\d{9}$/,
    prefix: "+34"
  }
}

const Contact: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)

  const validatePhone = (value: string) => {
    if (!value || !selectedCountry) return true // Teléfono es opcional
    
    const format = PHONE_FORMATS[selectedCountry]
    const cleanPhone = value.replace(/\D/g, '')
    
    if (!format.regex.test(cleanPhone)) {
      setPhoneError(`Formato inválido para ${format.name}`)
      return false
    }

    setPhoneError('')
    return true
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPhone(value)
    validatePhone(value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validatePhone(phone)) return

    try {
      // Aquí iría la lógica de envío del formulario
      await new Promise(resolve => setTimeout(resolve, 1000))
      
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      })
      
      setFormSubmitted(true)
      // Resetear el formulario
      const form = e.currentTarget as HTMLFormElement
      form.reset()
      setPhone('')
      setSelectedCountry('')
      setPhoneError('')
    } catch (error) {
      console.error('Error al enviar el formulario:', error)
    }
  }

  return (
    <section id="contacto" className="py-32 bg-black/50">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Contacto</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Agenda una consulta gratuita y descubre cómo podemos ayudarte
          </p>
        </motion.div>

        <div className="max-w-3xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="p-8 border border-white/10 rounded-xl bg-black/20"
          >
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Nombre */}
              <div>
                <label htmlFor="name" className="block text-white font-medium mb-2">
                  Nombre
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors"
                  placeholder="Tu nombre completo"
                />
              </div>

              {/* Empresa y Rol */}
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="company" className="block text-white font-medium mb-2">
                    Empresa
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    required
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors"
                    placeholder="Nombre de tu empresa"
                  />
                </div>

                <div>
                  <label htmlFor="role" className="block text-white font-medium mb-2">
                    Cargo
                  </label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    required
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors"
                    placeholder="Tu cargo en la empresa"
                  />
                </div>
              </div>

              {/* Email */}
              <div>
                <label htmlFor="email" className="block text-white font-medium mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors"
                  placeholder="tu@email.com"
                />
              </div>

              {/* País y Teléfono */}
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="country" className="block text-white font-medium mb-2">
                    País
                  </label>
                  <select
                    id="country"
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value)
                      setPhone('')
                      setPhoneError('')
                    }}
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors"
                  >
                    <option value="">Selecciona un país</option>
                    {Object.entries(PHONE_FORMATS).map(([code, format]) => (
                      <option key={code} value={code}>
                        {format.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="phone" className="block text-white font-medium mb-2">
                    Teléfono (opcional)
                  </label>
                  <div className="relative">
                    {selectedCountry && (
                      <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400">
                        {PHONE_FORMATS[selectedCountry].prefix}
                      </span>
                    )}
                    <InputMask
                      mask={selectedCountry ? PHONE_FORMATS[selectedCountry].mask : ''}
                      value={phone}
                      onChange={handlePhoneChange}
                      disabled={!selectedCountry}
                    >
                      {(inputProps: any) => (
                        <input
                          {...inputProps}
                          type="tel"
                          id="phone"
                          className={`w-full px-4 py-3 ${selectedCountry ? 'pl-16' : ''} bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors ${
                            phoneError ? 'border-red-500' : ''
                          }`}
                          placeholder={selectedCountry ? PHONE_FORMATS[selectedCountry].mask : "Selecciona un país primero"}
                        />
                      )}
                    </InputMask>
                  </div>
                  {phoneError && (
                    <p className="mt-1 text-sm text-red-500">{phoneError}</p>
                  )}
                </div>
              </div>

              {/* Mensaje */}
              <div>
                <label htmlFor="message" className="block text-white font-medium mb-2">
                  Mensaje
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:border-neon-green focus:ring-1 focus:ring-neon-green outline-none transition-colors resize-none"
                  placeholder="Cuéntanos sobre tu proyecto..."
                ></textarea>
              </div>

              {/* Botón de envío */}
              <motion.button
                type="submit"
                className="w-full px-8 py-4 bg-neon-green text-black font-bold rounded-lg hover:bg-neon-green/90 transition-colors disabled:opacity-50"
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
              >
                Enviar Mensaje
              </motion.button>

              {/* Mensaje de éxito */}
              {formSubmitted && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-center text-neon-green"
                >
                  ¡Mensaje enviado con éxito! Nos pondremos en contacto contigo pronto.
                </motion.p>
              )}
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Contact 