import React from 'react'
import { motion } from 'framer-motion'

const Methodology: React.FC = () => {
  const steps = [
    {
      number: "01",
      title: "Diagnóstico",
      description: "Análisis profundo de la situación actual",
      practices: [
        "Evaluación de procesos",
        "Análisis de tecnología",
        "Identificación de oportunidades",
        "Definición de objetivos"
      ]
    },
    {
      number: "02",
      title: "Estrategia",
      description: "Diseño de la hoja de ruta digital",
      practices: [
        "Planificación estratégica",
        "Definición de KPIs",
        "Roadmap tecnológico",
        "Gestión de riesgos"
      ]
    },
    {
      number: "03",
      title: "Implementación",
      description: "Ejecución ágil y medible",
      practices: [
        "Metodologías ágiles",
        "Desarrollo iterativo",
        "Control de calidad",
        "Gestión del cambio"
      ]
    },
    {
      number: "04",
      title: "Optimización",
      description: "Mejora continua y escalabilidad",
      practices: [
        "Monitoreo de resultados",
        "Ajustes estratégicos",
        "Escalabilidad",
        "Innovación continua"
      ]
    }
  ]

  return (
    <section id="metodologia" className="py-32 bg-black/50">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Metodología</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Un enfoque estructurado para garantizar resultados excepcionales
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <MethodologyStep
              key={index}
              {...step}
              index={index}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2 }}
          className="mt-16 p-8 border border-white/10 rounded-xl bg-black/20"
        >
          <div className="grid md:grid-cols-3 gap-8">
            <MethodologyBenefit
              icon="🎯"
              title="Resultados Medibles"
              description="Objetivos claros y métricas definidas para cada etapa del proceso"
            />
            <MethodologyBenefit
              icon="⚡"
              title="Agilidad"
              description="Adaptación rápida a cambios y necesidades emergentes"
            />
            <MethodologyBenefit
              icon="🔄"
              title="Mejora Continua"
              description="Optimización constante basada en resultados y feedback"
            />
          </div>
        </motion.div>
      </div>
    </section>
  )
}

interface MethodologyStepProps {
  number: string
  title: string
  description: string
  practices: string[]
  index: number
}

const MethodologyStep: React.FC<MethodologyStepProps> = ({
  number,
  title,
  description,
  practices,
  index
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ delay: index * 0.1 }}
    className="relative p-6 border border-white/10 rounded-xl bg-black/20 hover:border-neon-green/30 transition-all duration-300"
  >
    <span className="text-6xl font-bold text-neon-green/20 absolute top-4 right-4">{number}</span>
    <div className="relative">
      <h4 className="text-xl font-bold text-white mb-3">{title}</h4>
      <p className="text-gray-400">{description}</p>
    </div>
    <ul className="space-y-2 mt-4">
      {practices.map((practice, index) => (
        <li key={index} className="flex items-center text-gray-400">
          <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
          {practice}
        </li>
      ))}
    </ul>
  </motion.div>
)

interface MethodologyBenefitProps {
  icon: string
  title: string
  description: string
}

const MethodologyBenefit: React.FC<MethodologyBenefitProps> = ({ icon, title, description }) => (
  <div className="text-center">
    <span className="text-4xl mb-4 block">{icon}</span>
    <h4 className="text-lg font-semibold text-white mb-2">{title}</h4>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
)

export default Methodology 