import React from 'react'
import { motion } from 'framer-motion'

const Frameworks: React.FC = () => {
  const frameworks = [
    {
      title: "Gestión de Proyectos",
      icon: "📊",
      frameworks: [
        {
          name: "PMBOK",
          aspects: [
            "Gestión del alcance",
            "Control de costos",
            "Gestión de riesgos",
            "Calidad del proyecto"
          ]
        },
        {
          name: "Agile & Scrum",
          aspects: [
            "Sprints iterativos",
            "Entregas incrementales",
            "Adaptabilidad",
            "Mejora continua"
          ]
        }
      ]
    },
    {
      title: "Gobierno de TI",
      icon: "🔄",
      frameworks: [
        {
          name: "COBIT 2019",
          aspects: [
            "Gobierno empresarial de TI",
            "Gestión de recursos",
            "Alineación estratégica",
            "Medición del desempeño"
          ]
        },
        {
          name: "ITIL v4",
          aspects: [
            "Gestión de servicios",
            "Mejora continua",
            "Valor del servicio",
            "Prácticas de gestión"
          ]
        }
      ]
    },
    {
      title: "Seguridad de la Información",
      icon: "🔒",
      frameworks: [
        {
          name: "ISO 27001",
          aspects: [
            "Sistema de gestión",
            "Evaluación de riesgos",
            "Controles de seguridad",
            "Mejora continua"
          ]
        },
        {
          name: "ISO 27002",
          aspects: [
            "Controles de referencia",
            "Mejores prácticas",
            "Seguridad organizacional",
            "Gestión de activos"
          ]
        }
      ]
    },
    {
      title: "Continuidad del Negocio",
      icon: "🔄",
      frameworks: [
        {
          name: "ISO 22301",
          aspects: [
            "Plan de continuidad",
            "Gestión de crisis",
            "Recuperación de desastres",
            "Resiliencia organizacional"
          ]
        },
        {
          name: "ISO 31000",
          aspects: [
            "Gestión de riesgos",
            "Marco de trabajo",
            "Proceso de evaluación",
            "Tratamiento de riesgos"
          ]
        }
      ]
    },
    {
      title: "Calidad de Servicios TI",
      icon: "⭐",
      frameworks: [
        {
          name: "ISO 20000",
          aspects: [
            "Gestión de servicios TI",
            "Entrega de servicios",
            "Relaciones con proveedores",
            "Control de procesos"
          ]
        },
        {
          name: "ISO 9001",
          aspects: [
            "Sistema de gestión",
            "Enfoque al cliente",
            "Mejora continua",
            "Gestión por procesos"
          ]
        }
      ]
    },
    {
      title: "Desarrollo de Software",
      icon: "💻",
      frameworks: [
        {
          name: "DevOps",
          aspects: [
            "Integración continua",
            "Entrega continua",
            "Automatización",
            "Colaboración"
          ]
        },
        {
          name: "CMMI",
          aspects: [
            "Madurez de procesos",
            "Mejora continua",
            "Calidad del software",
            "Gestión de proyectos"
          ]
        }
      ]
    }
  ]

  return (
    <section id="marcos" className="py-32">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Marcos y Buenas Prácticas</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Implementamos metodologías probadas y estándares internacionales como COBIT, ITIL, 
            ISO 20000, ISO 27001, ISO 27002, ISO 22301 y otros marcos de referencia para garantizar 
            la excelencia en la gestión y entrega de servicios
          </p>
        </motion.div>

        <div className="grid lg:grid-cols-3 gap-8">
          {frameworks.map((framework, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <FrameworkCard {...framework} />
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
          className="mt-16 text-center"
        >
          <a 
            href="#contacto"
            className="inline-flex items-center gap-2 px-8 py-4 bg-neon-green text-black font-bold rounded hover:bg-neon-green/90 transition-colors"
          >
            Implementa estos marcos en tu empresa
            <span className="text-xl">→</span>
          </a>
        </motion.div>
      </div>
    </section>
  )
}

interface FrameworkCardProps {
  title: string
  icon: string
  frameworks: {
    name: string
    aspects: string[]
  }[]
}

const FrameworkCard: React.FC<FrameworkCardProps> = ({ title, icon, frameworks }) => (
  <div className="p-8 border border-white/10 rounded-xl bg-black/20 hover:border-neon-green/30 transition-all duration-300 h-full">
    <div className="flex items-center gap-3 mb-6">
      <span className="text-3xl">{icon}</span>
      <h3 className="text-xl font-bold text-white">{title}</h3>
    </div>
    <div className="space-y-6">
      {frameworks.map((framework, index) => (
        <div key={index}>
          <h4 className="text-neon-green font-semibold mb-3">{framework.name}</h4>
          <ul className="space-y-2">
            {framework.aspects.map((aspect, i) => (
              <li key={i} className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                {aspect}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
)

export default Frameworks 