import React from 'react'
import { motion } from 'framer-motion'
import { BiCode, BiCloud, BiMobile, BiData, BiLock, BiLineChart, BiCog, BiWorld } from 'react-icons/bi'

const Services: React.FC = () => {
  const services = [
    {
      icon: <BiCode className="text-neon-green text-4xl" />,
      title: "Gestión de Proyectos",
      description: "Dirección estratégica de proyectos tecnológicos, asegurando entregas exitosas y ROI maximizado."
    },
    {
      icon: <BiCloud className="text-neon-green text-4xl" />,
      title: "Transformación Digital",
      description: "Implementación de estrategias digitales para optimizar procesos y mejorar la competitividad."
    },
    {
      icon: <BiMobile className="text-neon-green text-4xl" />,
      title: "Innovación Tecnológica",
      description: "Identificación e implementación de tecnologías emergentes para impulsar el crecimiento empresarial."
    },
    {
      icon: <BiData className="text-neon-green text-4xl" />,
      title: "Analítica de Datos",
      description: "Implementación de soluciones de Big Data y análisis predictivo para toma de decisiones."
    },
    {
      icon: <BiLock className="text-neon-green text-4xl" />,
      title: "Ciberseguridad",
      description: "Protección integral de activos digitales y gestión de riesgos tecnológicos."
    },
    {
      icon: <BiLineChart className="text-neon-green text-4xl" />,
      title: "Optimización de Procesos",
      description: "Reingeniería y automatización de procesos empresariales para mayor eficiencia."
    },
    {
      icon: <BiCog className="text-neon-green text-4xl" />,
      title: "Arquitectura Empresarial",
      description: "Diseño e implementación de arquitecturas tecnológicas escalables y sostenibles."
    },
    {
      icon: <BiWorld className="text-neon-green text-4xl" />,
      title: "Estrategia Digital",
      description: "Desarrollo de roadmaps digitales alineados con objetivos de negocio."
    }
  ]

  return (
    <section id="servicios" className="py-32 bg-black/50">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Servicios</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Soluciones integrales y personalizadas para impulsar tu transformación digital
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              {...service}
              index={index}
            />
          ))}
        </div>

        {/* Categorías de Servicios */}
        <div className="mt-16 grid md:grid-cols-2 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="p-8 border border-white/10 rounded-xl bg-black/20"
          >
            <h3 className="text-xl font-bold text-white mb-4">Servicios de Consultoría</h3>
            <ul className="space-y-3">
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Diagnóstico y evaluación digital
              </li>
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Planificación estratégica
              </li>
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Gestión del cambio
              </li>
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Optimización de procesos
              </li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="p-8 border border-white/10 rounded-xl bg-black/20"
          >
            <h3 className="text-xl font-bold text-white mb-4">Servicios Técnicos</h3>
            <ul className="space-y-3">
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Implementación de soluciones
              </li>
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Desarrollo de software
              </li>
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Integración de sistemas
              </li>
              <li className="flex items-center text-gray-400">
                <span className="w-1.5 h-1.5 rounded-full bg-neon-green mr-2"></span>
                Soporte y mantenimiento
              </li>
            </ul>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

interface ServiceCardProps {
  icon: React.ReactNode
  title: string
  description: string
  index: number
}

const ServiceCard: React.FC<ServiceCardProps> = ({ icon, title, description, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ delay: index * 0.1 }}
    className="p-8 border border-white/10 rounded-xl bg-black/20 hover:border-neon-green/30 transition-all duration-300"
  >
    <div className="mb-6">{icon}</div>
    <h3 className="text-xl font-bold text-white mb-4">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </motion.div>
)

export default Services 